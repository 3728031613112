<!--    广告投放管理-->
<!--todo
1.投放门店有问题，查出来的数据比18080端口多-->
<template>
    <div class="Launch" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="投放门店">
                            <el-select placeholder="投放门店" v-model="form.deptCode" filterable clearable>
                                <el-option label="请选择" value="" />
                                <el-option v-for="dt in meta.depts" :label="dt.name" :value="dt.code" :key="dt.code" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户名称">
                            <el-select placeholder="客户名称" v-model="form.customerCode" filterable clearable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="ct in meta.customers"
                                    :label="ct.name"
                                    :value="ct.code"
                                    :key="ct.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="广告名称">
                            <el-input placeholder="广告名称" v-model="form.search" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="广告类型">
                            <el-select placeholder="广告类型" v-model="form.adType" clearable>
                                <el-option label="请选择" value="" />
                                <el-option label="视频" value="1" />
                                <el-option label="图片" value="0" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="启用状态">
                            <el-select placeholder="启用状态" v-model="form.status" clearable>
                                <el-option label="请选择" value="" />
                                <el-option label="启用" value="1" />
                                <el-option label="停用" value="0" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="广告位置">
                            <el-select placeholder="广告位置" v-model="form.position" clearable>
                                <el-option label="请选择" value="" />
                                <el-option label="收银机主屏" value="1" />
                                <el-option label="收银机副屏" value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="开始日期">
                            <el-date-picker
                                placeholder="选择日期"
                                v-model="form.startTime"
                                value-format="yyyy-MM-dd"
                                :clearable="false"
                                :editable="false"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结束日期">
                            <el-date-picker
                                placeholder="选择日期"
                                v-model="form.endTime"
                                value-format="yyyy-MM-dd"
                                :clearable="false"
                                :editable="false"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleManualQuery"
                size="small"
                v-if="hasPrivilege('menu.advert.launch.open')"
            >
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.advert.launch.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="handleBatchEnable"
                size="small"
                v-if="hasPrivilege('menu.advert.launch.edit')"
                :disabled="disableBatchEnable"
                >批量启用
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.advert.launch.export')"
                >导出
            </el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="launchTable"
                border
                stripe
                ref="launchTable"
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="445"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="50" type="index" fixed="left" />
                <el-table-column label="投放门店" width="160" prop="deptName" :show-overflow-tooltip="true" />
                <el-table-column label="投放日期" width="133" prop="launchDate" />
                <el-table-column label="广告名称" width="160" prop="adName" :show-overflow-tooltip="true" />
                <el-table-column label="客户名称" width="160" prop="customerName" />
                <el-table-column label="广告类型" width="70" prop="adType">
                    <template slot-scope="scope">
                        <span>{{ scope.row.adType | adType }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="启用状态" width="80" prop="status">
                    <template slot-scope="scope">
                        <span>{{ scope.row.status | status }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="广告时长" width="70" prop="adTimeLength" />
                <el-table-column label="广告位置" width="100" prop="position">
                    <template slot-scope="scope">
                        <span>{{ scope.row.position | position }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="广告内容" width="160" prop="adContent" :show-overflow-tooltip="true" />
                <el-table-column label="创建人" width="120" prop="creator" />
                <el-table-column label="创建时间" width="133" prop="createTime" />
                <el-table-column label="操作" min-width="150" header-align="center">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            v-if="scope.row.status && hasPrivilege('menu.advert.launch.edit')"
                            @click="handleDisabled(scope.row.code)"
                            >停用
                        </el-button>
                        <el-button
                            size="mini"
                            v-if="!scope.row.status && hasPrivilege('menu.advert.launch.edit')"
                            type="success"
                            @click="handleEnable(scope.row.code)"
                            >启用
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row.code)"
                            :disabled="scope.row.status == 1"
                            v-if="hasPrivilege('menu.advert.launch.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog title="提示" :visible.sync="createDialogVisible" width="30%" center top="300px">
            <div style="display: flex; justify-content: center">
                <el-button type="primary" @click="handleLaunch(1)" size="medium">按门店设置投放广告</el-button>
                <el-button type="primary" @click="handleLaunch(0)" size="medium">按广告设置投放门店</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import { DeptTypeEnum } from 'js/DeptCommon';
import { delAfterConfirmed, postAfterConfirmed } from 'request/http';

export default {
    name: 'Launch',
    data() {
        return {
            form: {
                search: '',
                customerCode: '',
                deptCode: '',
                adType: '',
                page: 1,
                limit: Util.Limit,
                startTime: this.defaultStartTime(),
                endTime: this.defaultEndTime(),
                position: '',
            },
            meta: {
                customers: [],
                depts: [],
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            selectedBizArr: [],
            url: {
                page: '/advert/launch/pageExtend',
                delete: '/advert/launch/delete',
                enable: '/advert/launch/activate',
                disable: '/advert/launch/forbidden',
                batchDisable: '/advert/launch/batchDisable',
            },
            createDialogVisible: false,
        };
    },
    mounted() {
        this.inits();
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '0') {
                return '停用';
            }
            if (status == '1') {
                return '启用';
            }
        },
        adType(adType) {
            if (typeof adType == 'undefined') {
                return '';
            }
            if (adType == '0') {
                return '图片';
            }
            if (adType == '1') {
                return '视频';
            }
        },
        position(position) {
            if (typeof position == 'undefined') {
                return '';
            }
            if (position === 1) {
                return '收银机主屏';
            }
            if (position === 2) {
                return '收银机副屏';
            }
        },
    },
    computed: {
        disableBatchEnable() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const noneDisableStatusIndex = this.selectedBizArr.findIndex((e) => {
                //不等于停用状态
                return e.status !== 0;
            });
            return noneDisableStatusIndex >= 0;
        },
    },
    methods: {
        defaultStartTime() {
            const nowDate = new Date();
            nowDate.setMonth(nowDate.getMonth() - 2);
            return this.formatDate(nowDate);
        },
        defaultEndTime() {
            return this.formatDate(new Date());
        },
        handleSelectionChange(selections) {
            this.selectedBizArr = selections;
        },
        inits() {
            const _this = this;
            UrlUtils.Customer(_this, (data) => {
                _this.meta.customers = data;
            });
            this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
                this.meta.depts = rst;
            });
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleCreate() {
            this.createDialogVisible = true;
        },
        handleLaunch(type) {
            if (type) {
                //按门店设置投放广告
                Util.nameJump(this, 'menu.advert.launch.advToStore', [
                    '广告管理',
                    '投放管理',
                    '广告投放',
                    '按门店设置投放广告',
                ]);
            } else {
                //按广告设置投放门店
                Util.nameJump(this, 'menu.advert.launch.storeToAdv', [
                    '广告管理',
                    '投放管理',
                    '广告投放',
                    '按广告设置投放门店',
                ]);
            }
            this.createDialogVisible = false;
        },
        handleExport() {
            UrlUtils.Export(this, '广告投放管理', '/advert/launch/exportExtend', this.form);
        },
        async handleDisabled(code) {
            await postAfterConfirmed(this.url.disable, { code: code });
            this.handleQuery();
        },
        async handleDelete(code) {
            await delAfterConfirmed(this.url.delete, { code: code });
            this.handleQuery();
        },
        async handleEnable(code) {
            await postAfterConfirmed(this.url.enable, { codes: code });
            this.handleQuery();
        },
        handleBatchEnable() {
            const codes = this.$refs.launchTable.selection.map((s) => s.code).join(',');
            this.handleEnable(codes);
        },
    },
};
</script>

<style scoped>
.Launch .el-form-item {
    margin-bottom: 0;
}
</style>
